var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ax-table',{ref:"tableBox",attrs:{"columns":_vm.columns,"toolActions":_vm.toolActions,"show-search":true,"searchForm":_vm.searchForm,"showToolBar":true,"row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    },"dataSourceApi":_vm.dataSourceApis,"dataSourceParams":_vm.dataSourceParams,"scroll":{ y: '55vh', x: '80vw' }},on:{"delect":_vm.del,"export":_vm.expor,"exportAll":_vm.exportAll,"add":_vm.add,"action-column-click":_vm.actionColumnClick,"pop-confirm":_vm.popConfirm},scopedSlots:_vm._u([{key:"age",fn:function({ record }){return _c('div',{staticClass:"operName"},[_vm._v(" "+_vm._s(new Date().getFullYear() - parseInt(record.identityCard.substr(6, 4)))+" ")])}},{key:"cellName",fn:function({ record }){return _c('div',{staticClass:"operName"},[_vm._v(" "+_vm._s(record.cellName ? record.cellName : "--")+" ")])}},{key:"dangerLevel_dictText",fn:function({ record }){return _c('div',{staticClass:"operName"},[(record.dangerLevel !== '')?_c('a-button',{class:{
          grey: record.dangerLevel === '0',
          yellow:
            record.dangerLevel === '1' ||
            record.dangerLevel === '2' ||
            record.dangerLevel === '3',
          red: record.dangerLevel === '4' || record.dangerLevel === '5',
        }},[_vm._v(_vm._s(record.dangerLevel_dictText))]):_c('a-input',{staticStyle:{"border":"none","text-align":"center","background-color":"transparent"},attrs:{"disabled":"","value":"--"}})],1)}}])}),_c('add-people',{ref:"psychiatricPatientsInfo",on:{"refsh":_vm.refsh}}),_c('visit-records',{ref:"visitRecords"}),_c('watch-People',{ref:"psychiatricPatientsInfoDetail"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }